var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex flex-column pb-64"},[_c('div',{staticClass:"fixed promotion-top fixed-content-area z-index-10",class:`${_setup.headerAnimClass}`},[_c(_setup.SimpleTitleHeader,{staticClass:"white",attrs:{"title":"기획전"},on:{"goBack":_setup.onClickPrevIcon}})],1),_c('div',{staticClass:"relative category-promotion-header-margin-top mb-32"},[_c('div',{staticClass:"d-flex flex-column pt-14 mx-10"},[_c('span',{staticClass:"rixgo-bold font-g20-30 mb-10"},[_vm._v(_vm._s(_setup.promotion?.ongoingItem?.title))]),_c(_setup.EmptyPlaceHolder,{attrs:{"title":"진행 중인 기획전이 없어요.","show":_setup.promotion?.ongoingItem.promotionList.length === 0}}),(!_setup.promotion)?_c('div',_vm._l((2),function(n){return _c('div',{key:n,staticClass:"d-flex flex-column align-start justify-start mb-10",attrs:{"id":"promotionSkeleton"}},[_c('v-skeleton-loader',{staticClass:"border-radius-16",attrs:{"width":"100%","height":"240","type":"image"}}),_c('v-skeleton-loader',{staticClass:"mt-8 border-radius-16",attrs:{"width":"63%","height":"20px","type":"heading"}}),_c('v-skeleton-loader',{staticClass:"mt-2 border-radius-16",attrs:{"width":"59%","height":"18px","type":"heading"}}),_c('div',{staticClass:"d-flex fill-width mt-4"},[_c('v-skeleton-loader',{staticClass:"mr-2 border-radius-20",attrs:{"width":"20%","height":"18px","type":"heading"}}),_c('v-skeleton-loader',{staticClass:"border-radius-20",attrs:{"width":"20%","height":"18px","type":"heading"}})],1)],1)}),0):_vm._e(),(_setup.promotion?.ongoingItem.promotionList.length > 0)?_c('div',_vm._l((_setup.promotion?.ongoingItem.promotionList),function(item,index){return _c(_setup.PromotionCard,{key:index,staticClass:"pb-20",attrs:{"title":item.title,"date":item.displayPeriod,"img-url":item.promotionImage,"chips":item.promotionTagList,"type":"large"},nativeOn:{"click":function($event){_setup.onClickPromotionCard(
              item,
              `cate_planninglist_now_${_setup.numbering(index + 1)}`
            )}}})}),1):_vm._e()],1),_c('div',{staticClass:"d-flex flex-column mx-10 mt-10"},[_c('span',{staticClass:"rixgo-bold font-g20-30 mb-10"},[_vm._v(_vm._s(_setup.promotion?.closedItem.title))]),_c(_setup.EmptyPlaceHolder,{attrs:{"show":_setup.promotion?.closedItem.promotionList.length === 0,"title":"종료된 기획전이 없어요."}}),(_setup.promotion?.closedItem.promotionList.length > 0)?_c('div',{staticClass:"finished-promotion-lists"},_vm._l((_setup.promotion?.closedItem.promotionList),function(item,index){return _c(_setup.PromotionCard,{key:index,attrs:{"title":item.title,"type":"small","img-url":item.promotionImage,"chips":[]},nativeOn:{"click":function($event){_setup.onClickPromotionCard(
              item,
              `cate_planninglist_finished_${_setup.numbering(index + 1)}`
            )}}})}),1):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }